import React from 'react'
import jl_logo from "../Images/jl_logo.png"

function Footer() {
    return (
        <div style={{ boxSizing: "border-box", paddingTop: "1%", paddingBottom: "1%", textAlign: "center", borderTop: "black", borderTopWidth: "1px", borderTopStyle: "solid"}}>
            <img src={jl_logo} alt="jl logo" height="50" width="50"/> by john lovitsch
        </div>
    )
}

export default Footer
