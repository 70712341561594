import "./App.css";
import Header from "../Header/Header";
import { Redirect, Route, Switch } from "react-router-dom";
import About from "../Pages/About";
import Home from "../Pages/Home";
import Portfolio from "../Pages/Portfolio";
import Contact from "../Pages/Contact";
import "react-chat-widget/lib/styles.css";
import Footer from "../Header/Footer";
function App() {
  return (
    <div className="App">
        <Header />
      <div id="App-pages">
        <Switch>
          <Redirect exact path="/" to="/home" />
          <Route path="/about" component={About} />
          <Route path="/home" component={Home} />
          <Route path="/contact" component={Contact} />
          <Route path="/portfolio" component={Portfolio} />
        </Switch>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
