import React from "react";
import {
  Card,
  Row,
  Col,
  Image,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";

function Portfolio() {
  return (
    <div id="portfolio-page">
      <br />
      <Col lg={{ span: 4, offset: 4 }} sm={12} xs={12}>
        <Card className="card-shadow">
          <Card.Header>Quick links to my projects below:</Card.Header>
          <Card.Body>
            <ul id="portfolio-page-ul">
              <a href="#intuitChart">
                <li>intuitChart</li>
              </a>
              <a href="#BoardGameSocial">
                <li>BoardGameSocial</li>
              </a>
              <a href="#myTodo">
                <li>myTodo</li>
              </a>
              <a href="#NearFieldObjects">
                <li>NearFieldObjects</li>
              </a>
              <a href="#CocktailFinder">
                <li>CocktailFinder</li>
              </a>
            </ul>
          </Card.Body>
        </Card>
      </Col>
      <br />
      <Col lg={{ span: 8, offset: 4 }} sm={12} xs={12}>
        <Card id="card-shadow" style={{ padding: "1%" }}>
          <Card.Header style={{ textAlign: "center" }} id="intuitChart">
            <strong>intuitChart</strong>
          </Card.Header>
          <Card.Body style={{ textAlign: "start", padding: "0" }}>
            <Row style={{ padding: "0" }} lg={2} sm={12} xs={1}>
              <Col lg={8}>
                <iframe
                  src="https://www.youtube.com/embed/rps8iRibf6I"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col lg={4}>
                <ul>
                  <li>
                    Consulted with medical professionals to construct the apps
                    functionality and goals
                  </li>
                  <li>
                    Researched medical charting needs and possibilities for
                    functional market use
                  </li>
                  <li>
                    Constructed frontend JS/React framework for UI interaction
                  </li>
                  <li>
                    Built Rails API backend with JSON web token and custom
                    authorization and authorization
                  </li>
                  <li>
                    Developed user-to-user messaging using Rails ActionCable and
                    SocketIO
                  </li>
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <br />
      <Col lg={{ span: 8 }} sm={12} xs={12}>
        <Card id="card-shadow" style={{ padding: "1%" }}>
          <Card.Header style={{ textAlign: "center" }} id="BoardGameSocial">
            <strong>BoardGameSocial:</strong> A board game and social networking
            web application with over 20k board games for multiple users to
            create libraries and share, comment, and rate board games across the
            network
          </Card.Header>
          <Card.Body>
            <Row lg={2} sm={12} xs={1}>
              <Col lg={8}>
                <iframe
                  src="https://www.youtube.com/embed/2u2Iaa4ZuOU"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                  title="BoardGameSocial"
                ></iframe>
              </Col>
              <Col lg={4}>
                <ul>
                  <li>
                    Developed Rails API and full CRUD functionality enabling
                    several users and user-to-user interaction
                  </li>
                  <li>
                    Designed algorithm for backend seeding of database and
                    designing the tables
                  </li>
                  <li>
                    Formatted frontend UI theme using Bulma and custom CSS
                    making a consistent UI experience
                  </li>
                  <li>Full Ruby on Rails web application</li>
                  <li>Developed user-to-user chat and comment funcitons</li>
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <br />
      <Col lg={{ span: 8, offset: 4 }}>
        <Card id="card-shadow">
          <Card.Header style={{ textAlign: "center" }} id="myTodo">
            <strong>myTodo:</strong> Todo task-lister is an app that enables a
            user to keep and track notes with comments and sorting
          </Card.Header>
          <Card.Body style={{ textAlign: "start" }}>
            <Row lg={2} sm={12} xs={1}>
              <Col lg={8}>
                <iframe
                  src="https://media.istockphoto.com/videos/moving-hand-drawn-lettering-coming-soon-alpha-channel-video-id1147664979"
                  frameborder="1"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                  title="CocktailFinder"
                ></iframe>
              </Col>
              <Col lg={4}>
                <ul>
                  <li>
                    Created full CRUD functionality with multiple users and
                    administrators using authentication and roles
                  </li>
                  <li>
                    Developed and implemented backend administrative security
                    creating the backend API
                  </li>
                  <li>
                    Instantiated comments that can be prioritized, ranked,
                    completed, and shared with other users
                  </li>
                  <li>
                    Installed a collaborative function with comments on shared
                    items
                  </li>
                  <li>
                    Incorporated sharing that is managed by the owner of the
                    item
                  </li>
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <br />
      <Col lg={{ span: 8 }}>
        <Card id="card-shadow">
          <Card.Header style={{ textAlign: "center" }} id="NearFieldObjects">
            <strong>NearFieldObjects:</strong> A web application that uses 3d
            rendering to map coordinates of over 14K different sattelies and
            debris in orbit around earth
          </Card.Header>
          <Card.Body style={{ textAlign: "start" }}>
            <Row lg={2} sm={12} xs={1}>
              <Col lg={8}>
                <iframe
                  src="https://media.istockphoto.com/videos/moving-hand-drawn-lettering-coming-soon-alpha-channel-video-id1147664979"
                  frameborder="1"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                  title="CocktailFinder"
                ></iframe>
              </Col>
              <Col lg={4}>
                <ul>
                  <li>
                    Created an algorithm to parse through over 130k lines of
                    non-code text to create 14k objects
                  </li>
                  <li>
                    Calculated coordinates of satellite positioning enabling
                    conversion of xyz coordinates
                  </li>
                  <li>
                    Utilized ThreeJS to enable 3D rendering of near field
                    objects in orbit
                  </li>
                  <li>
                    Developed full CRUD functionality and backend administrative
                    security creating the backend API
                  </li>
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <br />
      <br />
      <Col lg={{ span: 8, offset: 4 }} sm={12} xs={12}>
        <Card id="card-shadow">
          <Card.Header style={{ textAlign: "center" }} id="CocktailFinder">
            <strong>CocktailFinder:</strong> A Ruby CLI application that allows
            the user to lookup cocktails, search for cocktails by ingreiendts,
            and build your own "liquor cabinet" that can tell you the cocktails
            you can make with the items you have.
          </Card.Header>
          <Card.Body style={{ textAlign: "start", padding: "0" }}>
            <Row style={{ padding: "0" }} lg={2} sm={12} xs={1}>
              <Col lg={8}>
                <iframe
                  src="https://www.youtube.com/embed/RQuMZL-y_Gg"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col lg={4}>
                <ul>
                  <li>
                    Accessed an external API to generate over 400 cocktails
                  </li>
                  <li>
                    Desinged ability to discover what you can make with what you
                    have at home
                  </li>
                  <li>
                    Full CRUD capability, add new ingredients or cocktails with full descriptions and instructions
                  </li>
                  <li>Full React-Native app is in production...</li>
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>{" "}
      <br />
    </div>
  );
}

export default Portfolio;
