import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import jlclose from "../Images/jlclose.jpg";
function Home() {
  return (
    <div className="home">
      <br />
      <Row
        xs={1}
        sm={1}
        md={2}
        style={{ padding: "0", margin: "0" }}
        // id="title-pic-img"
      >
        <Col md={5} id="title-pic-img">
          <img src={jlclose} alt="my picture" id="jlimg" />
          <br />
        </Col>
        <Col md={5} id="title-pic-card">
          <Card >
            <h1>Welcome!</h1>
            <p>
              <em>
                We all can make the world better <br />
                once choice at a time.
              </em>
            </p>
          </Card>
        </Col>
      </Row>
      <br />
    </div>
  );
}

export default Home;
