import React from "react";
import {
  Card,
  Row,
  Col,
  Image,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { useState } from "react";
import emailjs from "emailjs-com";

function Contact() {
  const [validated, setValidated] = useState(true);

  function sendEmail(e) {
    console.log(e.target);
    e.preventDefault();
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    // //   e.preventDefault();
    //   e.stopPropagation();
    // }

    // setValidated(true);

    emailjs
      .sendForm(
        "gmail",
        "template_personal_site",
        e.target,
        "user_SLmMTIab5h0qj3YRKuAgs"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
      <div id="contact-page">
    <Col md={{ span: 6, offset: 3 }} style={{paddingTop: "1%", paddingBottom: "1%"}}>
<Card id="card-shadow">
    <Card.Header id="card-header">
        <p><strong>Want to get in touch?</strong></p>
        <p>Fill out the form below and I will get back to you in 24 hours or less!</p>
    </Card.Header>
    <Card.Body>
      <Form
        noValidate
        //   validated={validated}
        id="contact-form"
        onSubmit={sendEmail}
      >
        <Form.Group  controlId="validationCustom01">
          <Form.Label style={{marginBottom: "0"}}>First name</Form.Label>
          <Form.Control
            // required
            type="text"
            placeholder="First name"
            // defaultValue=""
            name="first_name"
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group  controlId="validationCustom02">
          <Form.Label style={{marginBottom: "0"}}>Last name</Form.Label>
          <Form.Control
            // required
            type="text"
            placeholder="Last name"
            // defaultValue=""
            name="last_name"
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>

        <Form.Group  controlId="validationCustom03">
          <Form.Label style={{marginBottom: "0"}}>Email</Form.Label>
          <Form.Control
            type="email"
            name="user_email"
            placeholder="Email"
            // required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email address.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group  controlId="validationCustom04">
          <Form.Label style={{marginBottom: "0"}}>Phone</Form.Label>
          <Form.Control
            type="text"
            name="phone"
            placeholder="Phone"
            name="contact_number"
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid phone number.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group  controlId="validationCustom05">
          <Form.Label style={{marginBottom: "0"}}>Company</Form.Label>
          <Form.Control
            type="text"
            name="company_name"
            placeholder="Company"
            // required
          />
          <Form.Control.Feedback type="invalid">
            What company do you represent?
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group  controlId="validationCustom06">
          <Form.Label style={{marginBottom: "0"}}>Message</Form.Label>
          <Form.Control as="textarea" name="message" placeholder="Message" />
        </Form.Group>
        <Button type="submit" value="Send" id="sbmt" >
          Submit Form
        </Button>
      </Form>
      </Card.Body>
      </Card>
    </Col></div>
  );
}

export default Contact;
