import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import Link from "@material-ui/core/Link";
import InfoIcon from "@material-ui/icons/Info";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import WorkIcon from "@material-ui/icons/Work";
import { NavLink } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Image,
} from "react-bootstrap";

import Repl from "../Images/Repl.png";
import Medium from "../Images/Medium.png";
import GitHub from "../Images/GitHub.png";
import LI from "../Images/LI.png";
import jl_logo_full from "../Images/jl_logo_full.png";

export default function Header() {
  return (
    <Navbar sticky="top" expand="lg" id="App-header" style={{ boxSizing: "border-box", paddingTop: "1%", paddingBottom: "1%", textAlign: "center", borderBottom: "black", borderBottomWidth: "1px", borderBottomStyle: "solid"}}>
      <Navbar.Brand href="home">
        <img
          alt="John Lovitsch"
          src={jl_logo_full}
          height="50"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        style={{ border: "none", color: "red" }}
      ></Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav" inline>
        <Nav className="mr-auto">
          <Nav.Link href="home" style={{ color: "black" }}>
            <HomeIcon /> Home
          </Nav.Link>
          <Nav.Link href="about" style={{ color: "black" }}>
            <InfoIcon /> About
          </Nav.Link>
          <Nav.Link href="contact" style={{ color: "black" }}>
            <ContactMailIcon /> Contact
          </Nav.Link>

          <Nav.Link href="portfolio" style={{ color: "black" }}>
            <WorkIcon /> Portfolio
          </Nav.Link>

          <NavDropdown title="Social Media" style={{ color: "black" }} id="basic-nav-dropdown">
            <NavDropdown.Item
              href="https://www.linkedin.com/in/john-lovitsch"
              target="_blank"
            >
              <Image src={LI} style={{ height: "1rem" }} /> LinkedIn
            </NavDropdown.Item>
            <NavDropdown.Item
              href="https://github.com/jmlovitsch"
              target="_blank"
            >
              <Image src={GitHub} style={{ height: "1rem" }} /> GitHub
            </NavDropdown.Item>
            <NavDropdown.Item
              href="https://repl.it/@JohnLovitsch"
              target="_blank"
            >
              <Image src={Repl} style={{ height: "1rem" }} /> Repl
            </NavDropdown.Item>
            <NavDropdown.Item
              href="https://jmlovitsch.medium.com/"
              target="_blank"
            >
              <Image src={Medium} style={{ height: "1rem" }} /> Medium
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
