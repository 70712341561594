import { Card, Col, Row } from "react-bootstrap";
import React from "react";

function About() {
  return (
    <div id="about-page">
      <br />
      <Row md={1} sm={1} xs={1} style={{ margin: "0", padding: "0" }}>
        <Col md={{ offset: "1", span: "6" }}>
          <Card>
            <Card.Header>
              <h3>A little about me...</h3>
            </Card.Header>
            <Card.Body>
              <p>
                I am a coder because I love problem solving and creative
                thinking. My strength in coding is backend development and data
                processing and system creation. I have a wide range of
                experience in different industries including supervising 40+
                employees, managing multi-million dollar programs, and
                developing startups.
              </p>
              <p>
                I am a creative, critial thinker that loves to solve problems
                and streamline basically anything. I have always enjoyed problem
                solving and critial thinking. Infact my favorite college course
                was Logic.
              </p>
              <p>
                I am not your average coder. I have a diverse background ranging
                from administration, teaching, counseling, and public speaking.
                I am an outgoing people person with diverse B to C experience,
                detailed oriented, accomplished, results-driven and
                multi-faceted professional with extensive experience driving
                operational efficiency and organizational profitability seeks a
                challenging new role within a progressive organization that
                fosters optimal business development and high-level operations
                skills.
              </p>
              <p>
                I am able to quickly establish sincere, professional
                relationships built on trust and honesty with diverse groups of
                co-workers, vendors and customers; solidify customer loyalty to
                increase account penetration rates and promote repeat
                business/referral generation. I provide exceptional and diligent
                service to a diverse client base to maintain customer allegiance
                to company and verify satisfaction, in an extremely fast-paced,
                deadline-driven environment. I have served as consultant to
                clientele, identifying needs and aligning them with applicable
                products/services.
              </p>
              <p>
                I am a team player who forms cohesive partnerships with key
                stakeholders; effectively drives participation, cross-functional
                collaboration and builds bridges to expand support and understanding.
              </p>
            </Card.Body>
          </Card>
          <br />
        </Col>

        <Col md={{ offset: "5", span: "6" }}>
          <Card>
            <Card.Header>
              <h3>Technical Skills</h3>
            </Card.Header>
            <Card.Body>
              <ol>
                <li>Core Languages</li>
                <ul>
                  <li>Ruby</li>
                  <li>Ruby on Rails</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>JavaScript</li>
                  <li>React</li>
                  <li>React-Redux</li>
                </ul>

                <li> Advancing Daily</li>
                <ul>
                  <li>Python</li>
                  <li>Java</li>
                  <li>React-Native</li>
                </ul>

                <li>Libraries I Know</li>
                <ul>
                  <li>Bootstrap</li>
                  <li>React-Bootstrap</li>
                  <li>Semantic UI</li>
                  <li>Material Design</li>
                </ul>
                <li>Other Proficiencies</li>
                <ul>
                  <li>Microsoft Office Suite</li>
                  <li>Adobe Suite</li>
                  <li>Google Platforms</li>
                </ul>
              </ol>
            </Card.Body>
          </Card>
          <br />
        </Col>

        <Col md={{ offset: "1", span: "6" }}>
          <Card>
            <Card.Header>
              <h3>Work Experience</h3>
            </Card.Header>
            <Card.Body>
              <h4>
                <strong>Project Manager</strong> at Gaming Advisors, LLC/Miller
                Companies, LLC 05/2019 - 09/2020
              </h4>
              <ul>
                <li>
                  Managed operations for multiple startups from sales and
                  customer satisfaction to budgeting
                </li>
                <li>
                  Built new company (Pilot Proficiency) from start to finish,
                  from site location to physical build-out, product launch, and
                  management
                </li>
              </ul>
              <h4>
                <strong>Parochial Vicar-Parish Administrator</strong> at Diocese
                of Joliet <span>05/2013 - 06/2019</span>
              </h4>
              <ul>
                <li>
                  Managed professional development and education for 40-plus
                  employees
                </li>
                <li>
                  Administered annual budget of $5M for physical plants, schools
                  and staff, and other programming
                </li>
              </ul>
            </Card.Body>
          </Card>
          <br />
        </Col>

        <Col md={{ offset: "5", span: "6" }}>
          <Card>
            <Card.Header>
              <h3>Education</h3>
            </Card.Header>
            <Card.Body>
              <h6>
                <strong>Flatiron School: </strong> Chicago, IL | 01/2021 |
                Certificate | Full Stack Web Development with Ruby on Rails and
                JavaScript
              </h6>
              <h6>
                <strong>John Paul the Great Catholic University: </strong>
                Escondido, CA | 06/2018 | Master of Arts | Biblical Exegesis
              </h6>
              <h6>
                <strong>University of Saint Thomas Aquinas in Urbe: </strong>
                Rome, Italy | 06/2013 | Master of Arts | Sacred Theology
              </h6>
              <h6>
                <strong>University of Saint Thomas Aquinas in Urbe: </strong>
                Rome, Italy | 06/2012 | Bachelor of Sacred Theology | Sacred
                Theology
              </h6>
              <h6>
                <strong>University of Saint Thomas Aquinas: </strong> St. Paul,
                MN | 05/2009 | Bachelor of Arts | Philosophy
              </h6>
            </Card.Body>
          </Card>
        </Col>
        <br />
      </Row>
      <br />
    </div>
  );
}

export default About;
